<template>
  <div class="flex flex-col gap-y-4 relative">
    <template v-if="loginStatus == '1'">
      <div class=" flex flex-col items-center gap-1">
        <span class=" text-black flex items-center">
          <a href="/user/vip" class=" text-blue-600 underline">个人中心</a>
        </span>
      </div>
    </template>
    <ul class=" !bg-transparent !border-r-0">
      <li v-for="m in menus" :key="m.title" class="px-[25px] py-[12px] text-center hover:bg-white hover:underline cursor-pointer">
        <a :href="m.href" class=" hover:underline">
          {{ m.title }}
        </a>
      </li>
    </ul>
  </div>
</template>
<script lang="ts" setup>

const defaultPath = ref()

const menus = [
  {
    href: "/",
    title: "主页",
  },
  {
    href: "/files",
    title: "文件",
  },
  {
    href: "/book",
    title: "图书",
  },
  {
    href: "/user/friends",
    title: "友情链接",
  },
]

const email = useCookie("email")
const type = useCookie("type")
const loginStatus = useCookie("login-status")

onMounted(() => {
  defaultPath.value = location.pathname
})

function selectMenu(path: string) {
  location.href = path
}

function errorHandler() {
  return true
}

function changePng() {
  alert("功能尚未开通")
}
</script>
<style lang="less" scoped>
.header-search {
  :deep(.el-input.is-disabled .el-input__inner) {
    cursor: pointer !important;
  }
}
</style>